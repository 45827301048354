/* Reset the margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  position: relative; 
  z-index: 1;   
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 10px;
  max-width: 900px;
}

.Site-logo {
  height: 96px;
}

.Top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
}

.Links {
  display: flex;
  gap: 20px;
}

.Links a, .Button {
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  background-color: #333;
  color: white;
  transition: background-color 0.3s ease;
}

.Links a:hover, .Button:hover {
  background-color: #555555;
}

@media only screen and (max-width: 768px) {
  
  .App-header {
    padding: 10px;
    border-radius: 5px;
  }

  .Site-logo {
    height: 60px;
  }

  .Nft-cards {
    justify-content: center;
  }

  .Nft-card {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .Nft-details {
    padding-left: 10;
    text-align: center;
  }

  .Nft-details h3 {
    font-size: 24px;
  }

  .Nft-details p {
    font-size: 16px;
  }

  .Marketplaces img {
    width: 36px;  /* Adjust the size of marketplace icons */
  }

  .Top-row, .Description-box {
    padding: 10px;
  }

  .Nft-name {
    max-width: 100%;
    white-space: normal;  /* Allow the text to wrap */
  }

  .Marketplace-links {
    justify-content: center;  /* Center the marketplace icons horizontally */
    margin-top: 10px;
  }
}

.Nft-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.Nft-details {
  padding-left: 10px;
}

.Nft-details h3 {
  font-size: 28px;  /* Updated to make the name larger */
  margin-bottom: 10px;  /* Added to add more space below the name */
  color: white; /* Updated to change the text color to white */
}

.Nft-details p {
  margin-bottom: 15px;  /* Updated to add more space between details */
  font-size: 18px;  /* Updated to make the text larger for better readability */
  color: white; /* Updated to change the text color to white */
}

.Nft-card {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center; 
  text-align: left;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.Nft-card:hover {
  transform: scale(1.05);
}

.Nft-image {
  image-rendering: pixelated; 
  border-radius: 5px;
  padding-left: 10px;
}

.Nft-info {
  margin-left: 20px;
}

.Nft-name {
  margin: 0;
  font-size: 18px;
  color: white;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.Marketplaces {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.Marketplace-icon {
  width: 24px;
  height: auto;
}

.Section-title {
  color: #fff;
  margin-bottom: 10px;
  text-align: left;
}

.Nft-section {
  margin-bottom: 40px;
}

.Marketplace-links {
  display: flex;
  gap: 10px;  /* Added to give space between the images */
  margin-top: 10px;
}

.Marketplace-links img {
  width: 48px;  /* Updated to change the size of the images */
  height: auto;
}

.Medium-article-promo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  max-width: 900px;
  width: 100%;
}

.Article-thumbnail {
  max-width: 192px;
  border-radius: 5px;
}

.Article-details {
  max-width: 600px;
  text-align: left;
  color: white;
}

.Article-details h2 {
  margin-bottom: 10px;
  font-size: 24px;
}

.Article-details p {
  margin-bottom: 20px;
}

.Read-more-button {
  display: inline-block;
  padding: 10px 20px;
  background: #333;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.Read-more-button:hover {
  background: #555;
}

.Nft-card.special-bg {
  background: #740000;
}

/* Add these styles to your existing App.css */
.Nft-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Add this if you want to style the Sprite ID */
.Nft-header span {
  font-size: 18px;
  color: white;
}

.flame-watermark {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 20px;  /* You can adjust the size */
  height: auto;
  z-index: 1;  /* To make sure it appears on top of other elements */
}
